import { AutoComplete, Button } from "dms-lib";
import React from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import PersonIcon from "@mui/icons-material/Person";
import { useApi, useI18n } from "compass-commons";
import { OnDemandIncidentFormDTO } from "../../../models/onDemandIncident/onDemandIncidentForm";
import { OptionUser, UserDTO } from "../../../models/users/UserDTO";
import UserManagerService from "../../../services/UserManagerService";

interface OperatorAssignProps {
  control: Control<OnDemandIncidentFormDTO>;
  setFieldValue: UseFormSetValue<OnDemandIncidentFormDTO>;
  watch: UseFormWatch<OnDemandIncidentFormDTO>;
  inputLabel?: string;
  currentUser: UserDTO;
}

const getOnlineOperators =
  (defaultSelection: OptionUser) => (): Promise<OptionUser[]> => {
    return UserManagerService.loadOnlineOperators()
      .then((response) => {
        const userList: OptionUser[] = response.users.map((user) => {
          return {
            ...user,
            id: user.userId,
            name: `${user.firstName} ${user.lastName}`,
          };
        });
        userList.unshift(defaultSelection);
        return userList;
      })
      .catch((error) => {
        throw Error(error);
      });
  };

const OperatorAssign = ({
  control,
  setFieldValue,
  watch,
  inputLabel,
  currentUser,
}: OperatorAssignProps): JSX.Element => {
  const { t: translate } = useI18n();
  const DEFAULT_OPERATOR: OptionUser = {
    id: null,
    userId: null,
    email: null,
    firstName: "",
    lastName: "",
    nameInitials: "",
    name: translate(
      isDMS
        ? "ondemand.operatorAssignAutomaticOption"
        : "ondemand.operatorAssignNoOneOption"
    ),
  };

  const handleAssignToMe = async () => {
    setFieldValue("assign", {
      id: currentUser.userId,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
    });
  };

  const onlineOperators = useApi(getOnlineOperators(DEFAULT_OPERATOR), true);

  return (
    <Controller
      name="assign"
      control={control}
      render={({ field: { onChange, value, ...field } }) => {
        return (
          <>
            <div>
              <div className="ondemand-form-section-label">
                {inputLabel || translate("ondemand.operatorAssignInputLabel")}
              </div>
              <AutoComplete<OptionUser>
                {...field}
                value={value as any}
                options={onlineOperators?.data || []}
                loading={onlineOperators.loading}
                dataCr="incident-selector-operator"
                getOptionLabel={(option) => option.name}
                onChangeCallback={(operator) => {
                  if (!operator) onChange(DEFAULT_OPERATOR);
                  else onChange(operator);
                }}
                onOpen={() => onlineOperators.refetch()}
                IconComponent={({ value: { id } }) =>
                  id === DEFAULT_OPERATOR.id && <PersonIcon />
                }
              />
            </div>
            {currentUser && (
              <Button
                variant="text"
                color="primary"
                onClick={handleAssignToMe}
                size="small"
                className="ondemand-form-self-assign-button"
                dataCr="incident-selector-self-assign"
                disabled={
                  watch("assign.email") === currentUser.email &&
                  currentUser.email !== null
                }
              >
                {translate("ondemand.assignToMeButton")}
              </Button>
            )}
          </>
        );
      }}
    />
  );
};

export default OperatorAssign;
